<template>
    <PfarreienComp></PfarreienComp>
</template>

<script>
    import PfarreienComp from '../components/Pfarreien.vue'
    export default {
        name: "Pfarreien",
        components: {
            PfarreienComp
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>

</style>